import React, { useEffect,useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import {useParams} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import moment from 'moment';
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Contact() {
    const didMountRef = useRef(true)
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const[partnerData , setPartnerData] = useState([])
    const [partnerImageUrl , setPartnerImageUrl] = useState("")
    const[defaultImage , setDefaultImage] = useState("")
    const [settingData, setSettingData] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [defaltImage, setDefaltImage] = useState('')
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')

    useEffect(() => {
        if (didMountRef.current) { 
       const dataString = {
      "page_url" : "contact"
       }
       apiServices.pagePostRequest(dataString).then(res => {
        if(res.data.status === "success"){
            if(res.data.pageData != null){
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url)
        }
       }
    })

    apiServices.newspartnerUrlGetRequest().then(res=>{
        if(res.data.status === "success"){
          setPartnerData(res.data.partnerData)        
          setPartnerImageUrl(res.data.partner_url)
          setDefaultImage(res.data.default_image_baseurl)
        }

      })

      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
            setImageUrl(res.data.settings_url)
            setSettingData(res.data.sitesettings)
            setDefaltImage(res.data.default_image_baseurl)
        }
    })


    }

  didMountRef.current = false;
  }); 


  const submitmessage = (e) => {
    e.preventDefault()
    if (Name == "") {
        document.getElementById("contact-name").style.border = "1px solid red"
        return false
    }
    else if (Email == "") {
        document.getElementById("contact-email").style.border = "1px solid red"
        return false
    }
    else if (Mobile == "") {
        document.getElementById("contact-phone").style.border = "1px solid red"
        return false
    }
    else if (Subject == "") {
        document.getElementById("contact-subject").style.border = "1px solid red"
        return false
    }
    else if (Message == "") {
        document.getElementById("contact-message").style.border = "1px solid red"
        return false
    }
    else {

        setName("")
        setEmail("")
        setMobile("")
        setSubject("")
        setMessage("")
        document.getElementById("contact-name").style.border = "1px solid var()"
        document.getElementById("contact-email").style.border = "1px solid var()"
        document.getElementById("contact-phone").style.border = "1px solid var()"
        document.getElementById("contact-subject").style.border = "1px solid var()"
        document.getElementById("contact-message").style.border = "1px solid var()"


        const dataString = {
            "contact_name": Name,
            "contact_email": Email,
            "contact_mobile": Mobile,
            "contact_subject": Subject,
            "contact_message": Message,
        }
        
        apiServices.contactusprocessPostRequest(dataString).then(res => {
            console.log(res);
            if (res.data.status === "success") {
                setSuccessmessage(res.data.message);
                setTimeout(() => {
                    window.location.href = '/thank-you'; 
                }, 2000);
            } else if (res.data.status === "error") {
                setErrorMessage(res.data.message);
            }
        });
        
    }
}

  return (
    <>
        <PagesHeader/>
    <div id="cappa-main">

    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center caption mt-90">
                    <h5></h5>
                 {pageData.page_header != null  ? <h1>{pageData.page_header}</h1>:""}
                </div>
            </div>
        </div>
    </div>
    <section className="contact section-padding">
        <div className="container">
            <div className="row mb-90">
                <div className="col-md-6 mb-60">
    
                    <h3><b>Chokhi Dhani</b></h3>
                    <div id="showbookingboxcontact" className='mcbooking mb-30'></div>
                    <p>Get in touch with us to reserve a table or for any inquiries regarding corporate events, social gatherings, and more.</p>
                    {/* <p>Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue the aucan ligula. Orci varius natoque penatibus et magnis dis parturient monte nascete ridiculus mus nellentesque habitant morbine.</p> */}
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-call"></span></div>
                        <div className="text">
                            <p>Reservations</p><a href={"tel:"+ settingData.admin_support_mobile} className="footer-contact-phone">{settingData.admin_support_mobile}</a><br/>
                            <a href={"tel:"+ settingData.admin_mobile} className="footer-contact-phone">{settingData.admin_mobile}</a>

                        </div>
                    </div>
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-envelope"></span></div>
                        <div className="text">
                            <p>Email Info</p><a href={"mailto:"+ settingData.admin_email}>{settingData.admin_email}</a>
                        </div>
                    </div>
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-location-pin"></span></div>
                        <div className="text">
                            <p>Address</p><a href="https://goo.gl/maps/U4in9GLkcYvvayJn8" target="new">{settingData.address}
                            <br/></a>
                        </div>
                    </div>
                    <div>
                    <h3><b>Travel Notes</b></h3>
                    <p>The nearest tube station is Vauxhall (Victoria Line) / Battersea Power Station (Northern Line). 
                        From Vauxhall Station (Stop E), you can either get a 156/344/436 bus. Alternatively, you could walk to the venue. 
                        A walking distance of 15 minutes along the river from Vauxhall Station and a 5 minutes’ walk from Battersea Power Station."</p>
                    </div>
                   
                </div>
                <div className="col-md-5 mb-30 offset-md-1">
                    <h3><b>Get in touch</b></h3>
                    {successMessage != "" ?
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div> : ""}
                                {errorMessage != "" ?
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ""}
                    {/* <form method="post" className="contact__form" action="#"> */}
                
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="alert alert-success contact__msg" style={{display: "none"}} role="alert"> Your message was sent successfully. </div>
                            </div>
                        </div> */}
            
                        {/* <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <input name="name" type="text" placeholder="Your Name *" required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="email" type="email" placeholder="Your Email *" required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="phone" type="text" placeholder="Your Number *" required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="subject" type="text" placeholder="Subject *" required/>
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <textarea name="message" id="message" cols="30" rows="4" placeholder="Message *" required></textarea>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="butn-dark2"><span>Send Message</span></button>
                            </div>
                        </div> */}


                    {/* </form> */}


                    <form method="post" className="contact__form" >

                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-name" id="contact-name"  type="text" placeholder="Your Name *" onChange={(e) => setName(e.target.value)} value={Name} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-email" id="contact-email"  type="email" placeholder="Your Email *" onChange={(e) => setEmail(e.target.value)} value={Email} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3" >
                                <input name="contact-phone" id="contact-phone"  type="number" placeholder="Your Number *" onChange={(e) =>  setMobile(e.target.value)} value={Mobile} required style={{ color:"#666"}}/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-subject" id="contact-subject"  type="text" placeholder="Subject *" onChange={(e) => setSubject(e.target.value)} value={Subject} required/>
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Message *" onChange={(e) => setMessage(e.target.value)} value={Message} required></textarea>
                            </div>
                           
                                        
                            <div className="col-md-12">
                                <button type="submit" className="butn-dark2" name="submit" onClick={submitmessage}><span>Send Message</span></button>
                            </div>
                        </div>


                    </form>
                </div>
            </div>
           
            <div className="row">
                <div className="col-md-12 map animate-box" data-animate-effect="fadeInUp" >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.691480713622!2d-0.13951412426024903!3d51.48217711254672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604fce8206467%3A0x6801d6ed861bf425!2sChokhi%20Dhani!5e0!3m2!1sen!2sin!4v1681897927270!5m2!1sen!2sin" width={"100%"} height={"600"} style={{border:"0"}} allowFullScreen="" loading={"lazy"}></iframe>
                </div>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.691480713622!2d-0.13951412426024903!3d51.48217711254672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604fce8206467%3A0x6801d6ed861bf425!2sChokhi%20Dhani!5e0!3m2!1sen!2sin!4v1681897927270!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
        </div>
    </section>
      <section className="testimonials mdbooking">
        <div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/CD_LondonHome.jpg" data-overlay-dark="2">
            <div className="container">
                <div className="row">
               
                    <div className="col-md-6 mb-30 mt-30">
                        <p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></p>
                        <h5>A journey through India's flavors.</h5>

                        <div className="reservations mb-30">
								<a href={"tel:"+settingData.admin_support_mobile}>
									<div className="icon color-1"><span className="flaticon-call"></span></div>
									<div className="text">
										<p className="color-1">Reservations</p> <a className="color-1" href={"tel:"+ settingData.admin_support_mobile}>{settingData.admin_support_mobile}</a>
									</div>
									</a>
								</div>


                        {/* <div className="reservations mb-30">
                            <div className="icon color-1"><span className="flaticon-call"></span></div>
                            <div className="text">
                                <p className="color-1">Reservations</p> <a className="color-1" href="tel:+44 7485 365962">+44 7485 365962</a>
                            </div>
                        </div> */}



                        {/* <p><i className="ti-check"></i><small>Call us, it's toll-free.</small></p> */}
                    </div>
                    
                    <div className="col-md-5 offset-md-1">
                        <div className="booking-box">
                            <div className="head-box">
                                {/* <h6>Rooms & Suites</h6> */}
                                {/* <h4>Chokhi Dhani London</h4> */}
                            </div>
                            <div className="booking-inner clearfix" id="showbookingboxcontact01">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
 
   

<section className="clients">
         <div className="container">
          <div className="row">
          <div className="col-md-7">
          <Swiper
                    modules={[Pagination, Autoplay]}
                   autoplay={{delay: 1500}}
                    spaceBetween={12}
                    breakpoints={{
                      // when window width is >= 640px
                      340: {
                        width: 340,
                        slidesPerView: 2,
                      },
                      640: {
                        width: 640,
                        slidesPerView: 2,
                      },
                      // when window width is >= 768px
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      },
                      1000: {
                        width: 1000,
                        slidesPerView: 4,
                      },
                      1200: {
                        width: 1200,
                        slidesPerView: 7,
                      },
                    }} >
                  {partnerData.map((value)=>(
                      <SwiperSlide>
          
                         <div className="clients-logo" >
                  <a href={value.client_link} target="_blank"><img src={value.client_image != null ? partnerImageUrl + value.client_image :defaultImage} alt={value.client_title} /></a>
                </div> 
               
                
                      </SwiperSlide>))}
                     



                    {/* <div className="swiper-button-next"></div> */}
                  </Swiper>
                  </div>
              
               
       
          </div>
        </div>
      </section>




    
    
   </div>


 <Footer/>



    </>
  )
}

export default Contact
import React from 'react'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <>
      <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-subtitle">OUR MENU </div> */}
              <h2 className="section-title">Our Menu</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/a-la-carte"><img src="/assets/img/food/Ala_Cart.jpeg" alt="" /></Link> </div>
                {/* <span className="category"><a href="#">Book</a></span> */}
                <div className="con">
                  {/* <h6><a href="#">150$ / Night</a></h6> */}
                  <h3><Link to="/menu/a-la-carte"> A LA CARTE</Link> </h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/menu/a-la-carte">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/wine-list"><img src="/assets/img/food/Wine.jpeg" alt="" /></Link> </div>
                {/* <span className="category"><a href="#">Book</a></span> */}
                <div className="con">
                  {/* <h6><a href="#">200$ / Night</a></h6> */}
                  <h3><Link to="/menu/wine-list">WINE MENU</Link></h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/menu/wine-list">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/cocktails"><img src="/assets/img/food/Cocktail.jpeg" alt="" /></Link> </div>
                <div className="con">

                  <h3><Link to="/menu/cocktails">COCKTAILS MENU</Link></h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/menu/cocktails">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/desserts"><img src="/assets/img/food/Dessert.jpeg" alt="" /></Link> </div>
                <div className="con">
                  <h3><Link to="/menu/desserts">DESSERTS</Link></h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/menu/desserts">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/valentines-menu"><img src="/assets/img/food/valentinemenu.jpg" alt="" /></Link> </div>
        
                <div className="con">
       
                  <h5><Link to="/menu/valentines-menu">VALENTINE'S MENU</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><Link to="/menu/valentines-menu">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/business-lunch-menu"><img src="/assets/img/food/Business.jpeg" alt="" /></Link> </div>
              
                <div className="con">
                
                  <h3><Link to="/menu/business-lunch-menu">BUSINESS LUNCH MENU</Link></h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><Link to="/menu/business-lunch-menu">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/take-away"><img src="/assets/img/food/TakeAway.jpeg" alt="" /></Link> </div>
                <div className="con">
                  <h3><Link to="/menu/take-away">TAKEAWAY MENU</Link></h3>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><Link to="/menu/take-away">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Menu
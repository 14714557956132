import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';

let apiServices = new ApiService();
function Newsletter() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Email, setEmail] = useState('')
  const [successMessage, setSuccessmessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const didMountRef = useRef(true);
  const [sliderData, setSliderData] = useState('')
  const [sliderImg, setSliderImg] = useState('')
  useEffect(() => {
    const visitedBefore = localStorage.getItem('visitedNewsletter');
    if (!visitedBefore) {
      handleShow();
      localStorage.setItem('visitedNewsletter', 'true');
    }
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      getSliderData();
    }

    didMountRef.current = false

  })

  const getSliderData = () => {
    apiServices.sliderDataGetRequest().then(res => {
      if (res.data.status == "success") {
        setSliderData(res.data.homeTopSliderData);
        setSliderImg(res.data.slider_img_path);
      }
    })

  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const submitmessage = (e) => {
    e.preventDefault()
    //console.log("Email",Email);
    if (Email === "") {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setEmail("")
      return false

    }
    if (!isValidEmail(Email)) {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setErrorMessage('Email is invalid');
      return false
    }
    setErrorMessage('');
    document.getElementById("newsletter_email").style.border = "1px solid #aa8453"
    const dataString = {
      "newsletter_email": Email,
    }

    apiServices.newsletterPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message)
        setEmail("")
      }
      else {
        setErrorMessage(res.data.message)
        setEmail("")
      }

      setTimeout(() => {
        document.getElementById("btn_timeout").click()
        window.location.reload();
      }, 2000);
    });
  }

  const handlenavigate=(item)=>{
    window.location.href=sliderData?.slider_url
  }
  return (
    <>
      {sliderData ? (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className='modal-body-with-background' style={{ backgroundImage: `url(${sliderImg + sliderData.slider_image})` }} onClick={()=>{handlenavigate()}}>
            <span><button type="button" className="btn-close" onClick={handleClose}></button></span>
           
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}

export default Newsletter;

import React, { useEffect, useRef,useState} from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import PagesHeader from "../../Components/PagesHeader";
import { ApiService } from "../../Components/Services/apiservices";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import moment from 'moment';
const apiServices = new ApiService()
function GalleryDetailPage(){
    const didMountRef = useRef(true)
    const{slug} = useParams()
    const [gallaryCategory , setGallaryCategory] = useState([])
    const [gallaryImageUrl , setGallaryImageUrl] = useState("")
    const [categoryData , setCategoryData] = useState({})
    const [pageData , setPageData] = useState({})
const [headerImage , setHeaderImage] = useState("")
useEffect(()=>{
    if(didMountRef.current){
        const dataString ={
            "category_slug" : slug
        }
 apiServices.categorywisegallaryPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
        setGallaryCategory(res.data.galleryData)
        setGallaryImageUrl(res.data.gallery_url)
        setCategoryData(res.data.category)       
      }
 })
 const dataString2 = {
  "page_url": "gallery"
}
apiServices.pagePostRequest(dataString2).then(res => {
  if (res.data.status === "success") {
    if (res.data.pageData != null) {
      setPageData(res.data.pageData)
      setHeaderImage(res.data.page_header_url)
    }
  }
})
  }
  didMountRef.current = false;
  }, []); 
   return(
        <>    
        <PagesHeader/>
        <div id="cappa-main">
      <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4" data-background="" >
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center caption mt-90">
                    <h5><a href="/gallary">Chokhi Dhani</a> </h5>
               {categoryData.category_name != null?<h1>{categoryData.category_name}</h1>:""}

                </div>
            </div>
        </div>
    </div>
    <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
         <div className="row">
          {gallaryCategory.map((value)=>(
            <div className="col-md-4">
              <div className="item">
                <div className="position-re o-hidden"> 
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgZoom]}>
                <a href={gallaryImageUrl+value.gallery_image}>
                <img src={value.gallery_image != null ?gallaryImageUrl + value.gallery_image : "/assets/img/defaultimage.png"}  alt="" /> 
                <div className="con">                

                </div> 
                </a>
                </LightGallery>
                </div>
              </div>
            </div>))}
          </div>
        </div>
      </section>
</div>
        <Footer/>
        </>
    )
}
export default GalleryDetailPage

import React from 'react'
function Services() {
  return (
    <>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="/menu"><img src="/assets/img/food/Restaurant.jpeg" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-blck valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Discover</h6>
                  </div>
                  <h2 className="section-title">Luxury Dining</h2>
                  <p>A distinctive fine-dining experience, the restaurant presents a luxurious pan-Indian cuisine, capturing the essence of Rajasthan's native and royal taste in every bite.</p>
                  <div className="butn-dark"> <a href="/menu"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-blck p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h2 className="section-title">Terrace Dining</h2>
                  <p>A unique alfresco dining experience with authentic Indian cuisine, refreshing drinks, and a relaxed atmosphere— perfect for socializing and unwinding with friends and family.</p>
                  <div className="butn-dark"> <a href="/terrace"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="/terrace"><img src="/assets/img/food/Shisha_Terrace.jpeg" alt="" /></a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="kalagram"><img src="/assets/img/food/Kalagram.jpeg" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-blck valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h2 className="section-title">Kalagram  – The Artisan Village</h2>
                  <p>Explore a wide range of Indian handicrafts, jewellery, and home decor from Kalagram, an exclusive brand available only at Chokhi Dhani. </p>
                  <div className="butn-dark"> <a href="kalagram"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-blck p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h2 className="section-title">Private dinning rooms</h2>
                  <p>Our intimate and distinctive dining experience is ideal for cocktail parties, receptions, birthdays, and business meetings. The elegant long table can accommodate up to 14 guests, and the floating capacity allows for up to 30 guests.</p>
                  <div className="butn-dark"> <a href="/private-events"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="/private-events"><img src="/assets/img/food/Dinning.jpeg" alt="" /></a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="/assets/pdf/dining.pdf" target="new"><img src="/assets/img/dining/Dinning.png" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-blck valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h2 className="section-title">Dining & Events</h2>
                  <p>Our restaurant provides a perfect blend of heritage and luxury setting with a spacious and elegant area that can accommodate up to 150 guests. Our team will work closely with you to create a bespoke experience that reflects your preferences and budget.</p>
                  <div className="butn-dark"><a href="/assets/pdf/dining.pdf" target="new"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6 bg-blck p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h2 className="section-title" >Private Room Bookings</h2>
                  <p>Luxurious and full of character, with its intricate carvings, original artwork and glittering chandeliers.</p>
                  <div className="butn-dark"> <a href="/private-room"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="/private-room"><img src="/assets/img/dining/Room.jpg" alt="" /></a>
              </div>
            </div>
          </div>




        </div>
      </section>

    </>
  )
}

export default Services
import React, { useEffect, useState, useRef,useContext } from 'react';
import {useLocation} from "react-router-dom"; 
import DataContext from '../Elements/eventContext';
import { ApiService } from '../Services/apiservices';

function PagesHeader() {
    let apiServices = new ApiService();

const location = useLocation();
const contextValues = useContext(DataContext);
const didMountRef = useRef(true);

useEffect(()=>{
    if(didMountRef.current){

        apiServices.settingsdataGetRequest().then(res => {
            if (res.data.status == "success") {
                contextValues.setImageUrl(res.data.settings_url)
                contextValues.setSettingData(res.data.sitesettings)
            }
        })


    }
    didMountRef.current = false


})

    return (
        <>
            <div className="progress-wrap cursor-pointer">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            <div className="sidebutton">
            <a href="/reservations" className="reserbutton">Reservations</a>
            <a href="/special-offers" className="whatsnewbutton">Special Offers</a>
            </div>
            <nav className="navbar navbar-expand-md">
                <div className="container">
                <div className="logo-wrapper mlogo">
                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>
                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className="navbar-nav mx-auto">
                    <li className="nav-item"><a href="/" className={location.pathname=== "/" ?"nav-link active":"nav-link"}>Home</a></li>
                    <li className="nav-item"><a href="/about-us" className={location.pathname.includes("/about-us") ?"nav-link active":"nav-link"}>About</a></li>
                    <li className="nav-item"><a href="/private-dining-room" className={location.pathname.includes("/private-dining-room") ?"nav-link active":"nav-link"}>Private Dining</a></li>
                    <li className="nav-item"><a href="/kalagram" className={location.pathname.includes("/kalagram") ?"nav-link active":"nav-link"}>Kalagram</a></li>
                    <li className="nav-item"><a href="/special-offers" className={location.pathname.includes("/special-offers") ?"nav-link active":"nav-link"}>Special Offers</a></li>
                   
                     
                    <div className="logo-wrapper mclogo">
                     <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>

                     </div>
                     <li className="nav-item"><a href="/terrace" className={location.pathname.includes("/terrace") ?"nav-link active":"nav-link"}>Terrace Dining</a></li>
                     <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu") ?"nav-link active":"nav-link"}>Menu</a></li>
                    
                    {/* <li className="nav-item"><a href="/news" className={location.pathname.includes("/news") ?"nav-link active":"nav-link"}>In News</a></li> */}
                    <li className="nav-item"><a href="/private-events" className={location.pathname.includes("/private-events") ?"nav-link active":"nav-link"}>Events</a></li>
                    <li className="nav-item"><a href="/gallery" className={location.pathname.includes("/gallery") ?"nav-link active":"nav-link"}>Gallery</a></li>
                    <li className="nav-item"><a href="/contact" className={location.pathname.includes("/contact") ?"nav-link active":"nav-link"}>Contact</a></li>
                    <li className="nav-item mres"><a href="/reservations" className={location.pathname.includes("/reservations") ?"nav-link active":"nav-link"}>Reservations</a></li>
                      
                      </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default PagesHeader
import React from 'react';
import Footer from "../../Components/Footer"


function NotFound(){

  return (
    <>
    {/* <Header/> */}
    <section className="section-padding mb-5">
   <div className="container">
   <div className="row">
    <div className="col-lg-12">
       <div className="text-center ">
      <img src="/assets/img/Chokhi_Dhani.png" style={{width:"300px"}}/>
      <h1>Page Not Found</h1>
      <p>The Page that you are looking for is either getting updated or there's server issue.
Kindly revisit again at: <a href="/">https://www.chokhidhani.co.uk/</a></p>

<p>If the issue pertains, Mail us at: <a href={"mailto:" + "info@chokhidhani.co.uk"}>info@chokhidhani.co.uk</a></p>
    </div>
   </div>
   </div>
   </div>
   </section>
        <Footer/>
        </>
  );
};

export default NotFound;
import React, { useState, useRef, useEffect,useContext } from 'react'
import { ApiService } from "../../Components/Services/apiservices";
import DataContext from '../../Components/Elements/eventContext';

let apiServices = new ApiService();

function Reservation() {
	const [calenderDate, setCalenderDate] = useState("")
	const [showLogin, setShowLogin] = useState(false);
	const didMountRef = useRef(true)
	const [settingData, setSettingData] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [defaultImage, setDefaultImage] = useState('')
	const contextValues = useContext(DataContext);

	useEffect(() => {
		if (didMountRef.current) {
			var today = new Date()
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();
			var calender = dd + "/" + mm + "/" + yyyy;
			setCalenderDate(calender)

		}
		didMountRef.current = false;
	}, []);
	return (
		<>
			<section className="testimonials">
				<div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/slider/2.jpg" data-overlay-dark="2">
					<div className="container">
						<div className="row">
							<div className="col-md-5 mb-30 mt-30">
								<p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></p>
								<h5>A journey through India's flavors.</h5>

								<div className="reservations mb-30">
								<a href={"tel:"+ contextValues.settingData.admin_support_mobile}>
									<div className="icon color-1"><span className="flaticon-call"></span></div>
									<div className="text">
										<p className="color-1">Reservations</p> <a className="color-1" href={"tel:"+ contextValues.settingData.admin_support_mobile}>{contextValues.settingData.admin_support_mobile}</a>
									</div>
									</a>
								</div>
							</div>
							<div className="col-md-5 offset-md-2">
								<div className="booking-box">

									<div className="booking-inner clearfix" id='bookingopentable'>
										<span className="spiralloader" style={{marginLeft:"auto" , marginRight:"auto", display:"block"}}></span>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}

export default Reservation
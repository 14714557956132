import React, { createContext, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [settingData, setSettingData] = useState({});
    const [imageUrl, setImageUrl] = useState('');

    return (
        <DataContext.Provider value={
          {
            settingData, setSettingData,
            imageUrl, setImageUrl,
        }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
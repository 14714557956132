import React, { useEffect, useState, useRef, useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import DataContext from '../Elements/eventContext';

let apiServices = new ApiService();
function Footer() {
  const didMountRef = useRef(true);
  const [Email, setEmail] = useState('')
  const [successMessage, setSuccessmessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const contextValues = useContext(DataContext);
  useEffect(() => {
    if (didMountRef.current) {
    }
    didMountRef.current = false;
  }, [])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const submitmessage = (e) => {
    e.preventDefault()
    //console.log("Email",Email);
    if (Email === "") {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setEmail("")
      return false

    }
    if (!isValidEmail(Email)) {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setErrorMessage('Email is invalid');
      return false
    }
    setErrorMessage('');
    document.getElementById("newsletter_email").style.border = "1px solid #aa8453"
    const dataString = {
      "newsletter_email": Email,
    }

    apiServices.newsletterPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message)
        setEmail("")
      }
      else {
        setErrorMessage(res.data.message)
        setEmail("")
      }

      setTimeout(() => {
        document.getElementById("btn_timeout").click()
        window.location.reload();
      }, 2000);
    });
  }

  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-3">

                <div className="footer-column footer-about">
                  <div className="footer-logo" style={{ marginBottom: '10px' }}>
                    <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                  </div>
                  <h4 className="footer-title">Chokhi Dhani </h4>
                  <p className="footer-about-text"

                    dangerouslySetInnerHTML={{ __html: contextValues.settingData.admin_share_message }}>
                  </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className="col-md-6 col-6">
                    <div className="footer-column footer-explore clearfix">
                      <h4 className="footer-title">Useful Links</h4>
                      <ul className="footer-explore-list list-unstyled">
                        <li><a href="/private-events">Private Events</a></li>
                        <li><a href="/kalagram">Shop</a></li>
                        <li><a href="/walk-through">Walk Through</a></li>
                        <li><a href="/menu">Menu</a></li>
                        <li><a href="/terrace">Terrace</a></li>
                        <li><a href="/parking-video">Parking</a></li>
                        <li><a href="/news">In News</a></li>

                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-6">
                    <div className="footer-column footer-explore clearfix">
                      <h4 className="footer-title">Quick Links</h4>
                      <ul className="footer-explore-list list-unstyled">
                        <li><a href="/indian-food">Indian Food</a></li>
                        <li><a href="/about-us">About Us</a></li>
                        <li><a href="/career">Career</a></li>
                        <li><a href="/blogs">Blog</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/thank-you">Thank You</a></li>
                      </ul>
                      <div className="butn-dark"> <a href="/reservations"><span>Reservations</span></a> </div>
                    </div>

                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='newsletterbox'>
                      <h4 className="footer-title">Newsletter</h4>
                      {successMessage !== '' ? <div className="alert alert-success" role="alert" style={{ color: "white" }}>{successMessage}</div> : null}
                      {errorMessage !== '' ? <div className="alert alert-danger" role="alert" style={{ color: "white" }}>{errorMessage}</div> : null}

                      <div className="input-group footer-subscription-form">
                        <input type="email" className="form-control" id="newsletter_email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} value={Email}></input>
                        <button className="btn01" type="button" id="btn_timeout" onClick={submitmessage}>Subscribe</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="footer-column footer-contact">
                  <h4 className="footer-title">Contact</h4>
                  <div className="footer-contact-info">
                    <a href="https://goo.gl/maps/U4in9GLkcYvvayJn8" className="footer-contact-phone" target="new"><span className="flaticon-marker"></span> {contextValues.settingData.address}</a>
                  </div>
                  <div className="footer-contact-info">

                    <a href={"tel:" + contextValues.settingData.admin_support_mobile} className="footer-contact-phone" style={{ marginRight: "16px" }}><span className="flaticon-call"></span>  {contextValues.settingData.admin_support_mobile}</a>
                    <a href={"tel:" + contextValues.settingData.admin_mobile} className="footer-contact-phone" style={{ marginRight: "16px" }}><span className="flaticon-call"></span>  {contextValues.settingData.admin_mobile}</a>
                    <a href={"mailto:" + contextValues.settingData.admin_email} className="footer-contact-phone"><span className="flaticon-envelope"></span> {contextValues.settingData.admin_email}</a>
                  </div>
                  <h4 className="footer-title">Order Online</h4>
                  <div className="footer-about-social-list">
                    {
                      contextValues.settingData.ubereat_url != null ?
                        <a href={contextValues.settingData.ubereat_url} target="new">
                          <img src="/assets/img/ubereat.png" alt="" />
                        </a>
                        : null}
                    {
                      contextValues.settingData.deliveroo_url != null ?

                        <a href={contextValues.settingData.deliveroo_url} target="new">
                          <img src="/assets/img/deliveroo.png" alt="" />
                        </a>
                        : null}
                    {
                      contextValues.settingData.justeat_url != null ?
                        <a href={contextValues.settingData.justeat_url} target="new">
                          <img src="/assets/img/justeat.png" alt="" />
                        </a>
                        : null}
                  </div>

                  <h4 className="footer-title mt-3">Follow us on</h4>
                  <div className="footer-about-social-list">

                    {
                      contextValues.settingData.facebook_url != null ?
                        <a href={contextValues.settingData.facebook_url} target="new"><i className="ti-facebook"></i></a>
                        : null}
                    {
                      contextValues.settingData.twitter_url != null ?
                        <a href={contextValues.settingData.twitter_url} target="new" ><i className="ti-twitter"></i></a>
                        : null}

                    {
                      contextValues.settingData.linkedin_url != null ?
                        <a href={contextValues.settingData.linkedin_url} target="new"><i className="ti-linkedin"></i></a>
                        : null}
                    {
                      contextValues.settingData.instagram_url != null ?
                        <a href={contextValues.settingData.instagram_url} target="new"><i className="ti-instagram" ></i></a>
                        : null}

                    {
                      contextValues.settingData.youtube_url != null ?
                        <a href={contextValues.settingData.youtube_url} target="new"><i className="ti-youtube"></i></a>
                        : null}


                    {
                      contextValues.settingData.pinterest_url != null ?
                        <a href={contextValues.settingData.pinterest_url} target="new"><i className="ti-pinterest"></i></a>
                        : null}

                    {
                      contextValues.settingData.tik_tok_url != null ?
                        <a href={contextValues.settingData.tik_tok_url} target="new"><img src="/assets/img/tiktok.png" alt="" />

                        </a>
                        : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-bottom-inner">
                  <p className="footer-bottom-copy-right">© Copyright 2023 by <a href="/">Chokhi Dhani. All rights reserved.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    
    </>
  )
}
export default Footer

import React, { useEffect, useRef, useState, useContext } from 'react';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Facilities from './Facilities';
import Pricing from './Pricing';
import PromoVideo from './PromoVideo';
import Menu from './Menu';
import Services from './Services';
import Testimonials from './Testimonials';
import Blogs from './Blogs';
import Resevation from './Reservation';
import Clients from './Clients';
import { ApiService } from "../../Components/Services/apiservices";
import Modal from 'react-bootstrap/Modal';
import Newsletter from '../../Components/Modal/newsletter';
import constant from '../../Components/Services/constant';
import DataContext from '../../Components/Elements/eventContext';


let apiServices = new ApiService();
function Home() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const didMountRef = useRef(true)

  const contextValues = useContext(DataContext);

  return (
    <>
      <Header />

      <section className="about section-padding" data-scroll-index="1">

        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-30" data-animate-effect="fadeInUp">
              <span>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </span>
              <div className="section-subtitle">Welcome to Chokhi Dhani</div>
              <h2 className="section-title">An Indian Culinary Adventure</h2>
              <h4><strong>Chokhi Dhani has championed local culture and hospitality in India for over 30 years.</strong></h4>
              <p>For over three decades, Chokhi Dhani has been preserving and promoting local culture and hospitality in India. We have brought the same vision and  essence to London, blending Royal Indian Cuisine, vibrant atmosphere, and exceptional hospitality from the land of Maharajas. Our menu is a testament to the legendary royal cuisine of Rajasthan, while also showcasing an eclectic selection of dishes from across India. </p>
              <div className="d-flex align-items-center about-button">
                <div className="butn-dark me-3">
                  <a href="/reservations"><span>Reservations</span></a>
                </div>
                <div className="reservations">
                  <div className="icon"><span className="flaticon-call"></span></div>
                  <div className="text">
                    <a href={"tel:" + contextValues.settingData.admin_support_mobile} className="footer-contact-phone">{contextValues.settingData.admin_support_mobile}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-md-3" data-animate-effect="fadeInUp">
              <img src="/assets/img/food/1_food.jpeg" alt="" className="mt-90 mb-30" />
            </div>
            <div className="col col-md-3" data-animate-effect="fadeInUp">
              <img src="/assets/img/food/2_food.jpeg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body className="login-modal-content"  >
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </Modal.Body>
      </Modal>
      <Menu />
      {/* <Pricing /> */}
      {/* <PromoVideo /> */}
      <Services />
      {/* <Testimonials /> */}
      {/* <Facilities /> */}

      <Blogs />
      <Resevation />
      <Clients />
      <Footer />

    </>
  )
}

export default Home
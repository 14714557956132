import React, { useEffect, useRef, useState } from 'react'
import PagesHeader from '../../Components/PagesHeader'
import Footer from '../../Components/Footer'
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();


function ThankYouPage() {
  const didMountRef = useRef(true)
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "thank-you"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)
          }
        }
      })
    }

    didMountRef.current = false;
  });
  return (

    <>

      <PagesHeader />

      <section className="section-padding-2 mt-4">
        <div className="step-first container text-center mt-80">
          <div className="section-title">
            <img src="/assets/img/check-circle.gif" style={{ width: '100px' }} className="mb-30" />
            <h2><strong>Thank You!</strong></h2>
            <h5>Your message has been sent. We will get back to you soon!</h5>

          </div>
          <div>
          </div>
        </div>
      </section>

      <Footer />
    </>


  )
}

export default ThankYouPage
import React, { useEffect, useState, useRef,useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import {useLocation} from "react-router-dom"; 
import Modal from 'react-bootstrap/Modal';
import Newsletter from '../Modal/newsletter';
import DataContext from '../Elements/eventContext';


let apiServices = new ApiService();
function Header(props) {
const location = useLocation();
    const didMountRef = useRef(true);
    const [show , setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const contextValues = useContext(DataContext);

    useEffect(() => {
        if (didMountRef.current) {
            apiServices.settingsdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    contextValues.setImageUrl(res.data.settings_url)
                    contextValues.setSettingData(res.data.sitesettings)
                }
            })
        }
    
        didMountRef.current = false;
    }, [])
    return (
        <>
                 
            <div className="progress-wrap cursor-pointer" >
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            <div className="sidebutton">
            <a href="/reservations" className="reserbutton">Reservations</a>
            <a href="/special-offers" className="whatsnewbutton">Special Offers</a>
            </div>
            {contextValues.settingData.marquee_tag ? (
          <div className="top-header">
            <div className ="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">
                  <marquee>{contextValues.settingData.marquee_tag}</marquee>
                </div>
              </div>
              </div>
          </div>
                ) : null}
            <nav className="navbar navbar-expand-md">
                <div className="container">
                <div className="logo-wrapper mlogo">
                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>
                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className="navbar-nav mx-auto">
                        <li className="nav-item"><a href="/" className={location.pathname.includes("/") ?"nav-link active":"nav-link"}>Home</a></li>
                    <li className="nav-item"><a href="/about-us" className={location.pathname.includes("/about-us") ?"nav-link active":"nav-link"}>About</a></li>
                    <li className="nav-item"><a href="/private-dining-room" className={location.pathname.includes("/private-dining-room") ?"nav-link active":"nav-link"}>Private Dining</a></li>
                    <li className="nav-item"><a href="/kalagram" className={location.pathname.includes("/kalagram") ?"nav-link active":"nav-link"}>Kalagram</a></li>
                   <li className="nav-item"><a href="/special-offers" className={location.pathname.includes("/special-offers") ?"nav-link active":"nav-link"}>Special Offers</a></li>
                       
                            <div className="logo-wrapper mclogo">
                                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                            </div>
                    <li className="nav-item"><a href="/terrace" className={location.pathname.includes("/terrace") ?"nav-link active":"nav-link"}>Terrace Dining</a></li>                  
                     {/* <li className="nav-item"><a href="/news" className={location.pathname.includes("/news") ? "nav-link active" : "nav-link"}>In News</a></li> */}
                     <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu") ?"nav-link active":"nav-link"}>Menu</a></li>
                  
                    <li className="nav-item"><a href="/private-events" className={location.pathname.includes("/private-events") ?"nav-link active":"nav-link"}>Events</a></li>
                    <li className="nav-item"><a href="/gallery" className={location.pathname.includes("/gallery") ?"nav-link active":"nav-link"}>Gallery</a></li>
                    <li className="nav-item"><a href="/contact" className={location.pathname.includes("/contact") ?"nav-link active":"nav-link"}>Contact</a></li>
                    <li className="nav-item mres"><a href="/reservations" className={location.pathname.includes("/reservations") ?"nav-link active":"nav-link"}>Reservations</a></li>
                    </ul>
                    </div>
                </div>
            </nav>
            <header className="header">
                <div className="video-fullscreen-wrap">
                    <div className="video-fullscreen-video" data-overlay-dark="6">
                        
                        {/* <video autoPlay loop src={'assets/video/London_Video.mp4'} muted playsinline /> */}
                        <video  src={'assets/video/London_Video.mp4'} autoplay="autoplay" loop muted playsInline>
                            </video>
                        {/* <video autoPlay loop >
<source src="/assets/video/video.mp4" type="video/mp4" autoPlay loop />
<source src="/assets/video/video.webm" type="video/webm" autoPlay loop />
</video> */}
                    </div>
                    <div className="v-middle caption overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <h4>CHOKHI DHANI</h4>
                                    <h1>Celebrate the Exotic Flavours of India</h1>
                                    <div className="butn-dark"><a href="/reservations" ><span>Reservations</span></a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reservation">
                <a href={"tel:"+ contextValues.settingData.admin_support_mobile}>
                        <div className="icon d-flex justify-content-center align-items-center">
                            <i className="flaticon-call"></i>
                        </div>
                        <div className="call" ><span>{contextValues.settingData.admin_support_mobile}</span> <br />
                        {/* Reservations */}
                        </div>
                    </a>
                </div>
                
                <div className="arrow bounce text-center">
                    <a href="#" data-scroll-nav="1" className=""> <i className="ti-arrow-down"></i> </a>
                </div>
            </header>
        <Newsletter
        
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        />
        </>
    )
}
export default Header

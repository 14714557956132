import React, { useCallback, useEffect, useRef, useState } from 'react';
import Footer from "../../Components/Footer"
import PagesHeader from "../../Components/PagesHeader"
import { useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import moment from "moment";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
let apiServices = new ApiService()
function NewsPage() {
  const lightGallery = useRef();
  const [items, setItems] = useState([
    {
      id: '1',
      // size: '300-200',
      src: '/assets/img/news/newsdt/naomi.jpg',
      thumb: '/assets/img/news/newsdt/naomi.jpg',
    },
    {
      id: '2',
      // size: '300-200',
      src: '/assets/img/news/newsdt/green.jpg',
      thumb: '/assets/img/news/newsdt/green.jpg',
    },
  ]);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);
  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img
            style={{ maxWidth: '280px' }}
            className="img-responsive"
            src={item.thumb}
          />
        </a>
      );
    });
  }, [items]);

  
  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page-url": "newsdetails"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
         
            setPageData(res.data.pageData)
            setHeaderImage(res.data.headerImage)
          
        }
      })
      lightGallery.current.refresh();
    }
  }, [items]);
  return (
    <>
      <PagesHeader />
      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
                <h5>Chokhi Dhani </h5>
                <h1>Launch Party</h1>
                {/* {pageData.page_header != null ?<h1>{pageData.page_header}</h1>:""} */}
              </div>
            </div>
          </div>
        </div>
        <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" style={{ width: "800px", height: "1202px" }}  >
                <LightGallery
                  plugins={[lgZoom]}
                  elementClassNames="custom-class-name"
                  onInit={onInit}
                >
                  {getItems()}
                </LightGallery>
              </div>
              <div className="col-lg-6" style={{ width: "800px", height: "1202px" }}  >
                <LightGallery
                  plugins={[lgZoom]}
                  elementClassNames="custom-class-name"
                  onInit={onInit}
                >
                  {getItems()}
                </LightGallery>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
export default NewsPage;